import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { useAuthStore } from "@commons/store"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:section?",
    name: "home",
    component: () => import("@/views/home.vue"),
  },
  {
    path: "/approvals/wallets/:wallet?/requests/:request?",
    name: "approvals",
    component: () => import("@/views/approvals.vue"),
  },
  {
    path: "/wallets/:wallet?/authentifications/:authentification?",
    name: "wallets",
    component: () => import("@/views/wallets.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/signup.vue"),
  },
  {
    path: "/activate",
    name: "activate",
    component: () => import("@/views/activate.vue"),
  },
  {
    path: "/activate-resend",
    name: "activate-resend",
    component: () => import("@/views/activate-resend.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/forgot-password.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(location.pathname.substr(0,3) === "/de" ? process.env.BASE_URL + "de/" : (location.pathname.substr(0,3) === "/lt" ? process.env.BASE_URL + "lt/" : (location.pathname.substr(0,3) === "/pl" ? process.env.BASE_URL + "pl/" : (location.pathname.substr(0,3) === "/it" ? process.env.BASE_URL + "it/" :  (location.pathname.substr(0,3) === "/es" ? process.env.BASE_URL + "es/" :  (location.pathname.substr(0,3) === "/fr" ? process.env.BASE_URL + "fr/" : process.env.BASE_URL)))))),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to) => {
  const storeAuth = useAuthStore();
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  const isLoggedin = (storeAuth as any).logged;
  if (requiresAuth && !isLoggedin) {
    return { name: 'login' }
  } else if (requiresGuest && isLoggedin) {
    return { name: 'home' }
  }
});

export default router;
