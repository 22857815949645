import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "@/App.vue";
import "./registerServiceWorker";
import router from "@/router";
import "@commons/assets/style/app.css";
import axios from "axios";
import notifications from "notiwind";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueGtag from "vue-gtag";
import { useAuthStore, usePathStore, useGlobalStore } from "@commons/store";

// Create Pinia store
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Create the Vue app
const app = createApp(App);

// Use plugins and global configurations
app
  .use(pinia)
  .use(router)
  .use(notifications)
  .use(VueReCaptcha, { 
    siteKey: process.env.VUE_APP_GCAPTCHA_PUBLIC_KEY,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
  .use(VueGtag, {
    appName: 'Viviswap',
    pageTrackerScreenviewEnabled: true,
    enabled: false,
    config: { 
      id: 'G-V325NQRRY0',
      params: {
        anonymize_ip: true,
      },
    },
  }, router);

// Enable performance mode
app.config.performance = true;

// Provide language information
const lang = {
  userLang: "en",
  userLangFull: "en_US",
};
app.provide('lang', lang);

// app.config.globalProperties.$lang = {
  // multilanguage not available
  /*
  userLang:
    location.pathname.substr(0, 3) === "/de"
      ? "de"
      : location.pathname.substr(0, 3) === "/lt"
      ? "lt"
      : location.pathname.substr(0, 3) === "/pl"
      ? "pl"
      : location.pathname.substr(0, 3) === "/it"
      ? "it"
      : location.pathname.substr(0, 3) === "/es"
      ? "es"
      : location.pathname.substr(0, 3) === "/fr"
      ? "fr"
      : "en",
  userLangFull:
    location.pathname.substr(0, 3) === "/de"
      ? "de_DE"
      : location.pathname.substr(0, 3) === "/lt"
      ? "lt_LT"
      : location.pathname.substr(0, 3) === "/pl"
      ? "pl_PL"
      : location.pathname.substr(0, 3) === "/it"
      ? "it_IT"
      : location.pathname.substr(0, 3) === "/es"
      ? "es_ES"
      : location.pathname.substr(0, 3) === "/fr"
      ? "fr_FR"
      : "en_US",
    */
    // userLang: "en",
    // userLangFull: "en_US",
// };

const langUrl =
lang.userLang === "en"
    ? "/"
    : "/" + lang.userLang + "/";
// const isDev = process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "biscuits";

const t = {"en_US":{"GENERAL_ERROR_CONTACTUS":"Sorry, an error has occurred. Please try again later or contact our support team.","GENERAL_SUPPORT_TITLE":"Support","ERROR_NETWORK":"A network error has occurred.","META_HOME":"Buy and sell crypto 2022 | SEPA, Klarna, DEX l From Bank Account to own Wallet.","GENERAL_ERROR":"Error","META_HOME_DESC":"Buy, sell and swap cryptocurrencies with euros - seamless and integrated FIAT onramp and offramp (SEPA), multi-chain swaps, DEX aggregators and more.","GENERAL_ERROR_SESSION_EXPIRED":"Your session is expired. You have been automatically logged out for security reasons.","GENERAL":"General","API":"API"}};

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {

    const storeAuth = useAuthStore()
    const storePath = usePathStore()
    const storeGlobal = useGlobalStore()

    if (response.status === 401) {
      storeGlobal.resetState();
      if (
        router.currentRoute.value.name !== "home"
      ) {
        (storePath as any).setNextPath(router.currentRoute.value.fullPath);
      }
      (storeAuth as any).logout();
      app.config.globalProperties.$notify(
        {
          group: "error",
          title: t[lang.userLangFull].GENERAL_ERROR,
          text: t[lang.userLangFull].GENERAL_ERROR_SESSION_EXPIRED,
        },
        4000
      );
      if(router.currentRoute.value.name !== "login") {
        // router.push({ name: "login" });
      }
    } else if (response.data.code) {
      axios
        .get(
          process.env.VUE_APP_API_TRANSLATION_URL +
            lang.userLangFull +
            "/translations?key=" +
            "E_" +
            response.data.code
        )
        .then((res) => {
          if (
            res &&
            res.status === 200 &&
            res.data["E_" + response.data.code]
          ) {
            app.config.globalProperties.$notify({
              group: "error",
              text: res.data["E_" + response.data.code] +
                ' <a class="underline" href="' +
                langUrl +
                'contact">' +
                t[lang.userLangFull].GENERAL_SUPPORT_TITLE +
                "</a>",
          }, 20000);
          } else {
            app.config.globalProperties.$notify(
              {
                group: "error",
                text:
                t[lang.userLangFull].GENERAL_ERROR_CONTACTUS +
                  " (" +
                  (response.data.code ? response.data.code : "") +
                  ")",
              },
              20000
            );
          }
        }).catch((err) => {
          if (err) {
            app.config.globalProperties.$notify(
              {
                group: "error",
                text: t[lang.userLangFull].GENERAL_ERROR_CONTACTUS,
              },
              20000
            );
          }
        });
    }
    
    return response;
  },
  function (error) {
    const storeAuth = useAuthStore()
    const storePath = usePathStore()
    const storeGlobal = useGlobalStore()
    
    if(error) {
      if((!error.response || typeof error.response === "undefined" || error.code === "ERR_NETWORK") && process.env.NODE_ENV !== "production") {
        app.config.globalProperties.$notify(
          {
            group: "error",
            text: error.code === "ERR_NETWORK" ? t[lang.userLangFull].ERROR_NETWORK : null
          }, 4000);
      } else if (error.response) {
        if (error.response.status === 401) {
          storeGlobal.resetState();
          if (
            router.currentRoute.value.name !== "home"
          ) {
            (storePath as any).setNextPath(router.currentRoute.value.fullPath);
          }
          (storeAuth as any).logout({ redirect: true });
          app.config.globalProperties.$notify(
            {
              group: "error",
              title: t[lang.userLangFull].GENERAL_ERROR,
              text: t[lang.userLangFull].GENERAL_ERROR_SESSION_EXPIRED,
            },
            20000
          );
          if(router.currentRoute.value.name !== "login") {
            // router.push({ name: "login" });
          }
        } else if (error.response.data && error.response.data.code && error.response.data.code.toString().length > 3) {
          axios
            .get(
              process.env.VUE_APP_API_TRANSLATION_URL +
                lang.userLangFull +
                "/translations?key=" +
                "E_" +
                error.response.data.code
            )
            .then((res) => {
              if (
                res &&
                res.status === 200 &&
                res.data["E_" + error.response.data.code]
              ) {
                app.config.globalProperties.$notify(
                  {
                    group: "error",
                    text:
                      res.data["E_" + error.response.data.code] +
                      ' <a class="hover:opacity-80 underline" href="' +
                      langUrl +
                      'contact">' +
                      t[lang.userLangFull].GENERAL_SUPPORT_TITLE +
                      "</a>",
                  },
                  20000
                );
                
              } else {
                app.config.globalProperties.$notify(
                  {
                    group: "error",
                    text:
                    t[lang.userLangFull].GENERAL_ERROR_CONTACTUS +
                      " (" +
                      (error.response.data ? error.response.data.code : "") +
                      ")",
                  },
                  20000
                );
              }
            })
            .catch((err) => {
              if (err) {
                app.config.globalProperties.$notify(
                  {
                    group: "error",
                    text: t[lang.userLangFull].GENERAL_ERROR_CONTACTUS,
                  },
                  20000
                );
              }
            });
        }
      }
      return Promise.reject(error.response);
    }
  }
);

app.mount("#app");

// Keep it! Global variables, t.
// evtl META_HOME
// evtl META_HOME_DESC
// t.GENERAL_ERROR_SESSION_EXPIRED
// t.GENERAL_SUPPORT_TITLE
// t.GENERAL_ERROR_CONTACTUS
// t.ERROR_NETWORK